import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"

const SeeMoreButton = ({ cta, link, left }) => (
  <a className="see-more-button" href={link}>
    {left && (
      <FontAwesomeIcon
        icon={faLongArrowAltLeft}
        className="margin-right arrow-left"
      />
    )}
    <span>{cta}</span>
    {!left && (
      <FontAwesomeIcon
        icon={faLongArrowAltRight}
        className="margin-left arrow-right"
      />
    )}
  </a>
)

export default SeeMoreButton
