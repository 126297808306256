import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import SEO from "../components/seo"
import Navbar from "../components/navbar"
import { Footer } from "../components/footer"
import SeeMoreButton from "../components/seeMoreButton"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const { previous, next } = this.props.pageContext

    return (
      <>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Navbar />
        <section className="section-wrapper bg-light-beige">
          <div className="section column-direction">
            <div className="blog-card-tag">Code</div>
            <h1 className="blog-post-title">{post.frontmatter.title}</h1>
            <p className="blog-post-date">{post.frontmatter.date}</p>
            <div className="blog-post-body">
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
            <ul className="ul-other-article">
              <li>
                {previous && (
                  <SeeMoreButton
                    cta={previous.frontmatter.title}
                    link={`/blog${previous.fields.slug}`}
                    left
                  />
                )}
              </li>
              <li>
                {next && (
                  <SeeMoreButton
                    cta={next.frontmatter.title}
                    link={`/blog${next.fields.slug}`}
                  />
                )}
              </li>
            </ul>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
